import Vue from "vue";
import store from "@/store";
import router from "@/router";
import shared from "@syncobox/syncobox-shared";

import "@syncobox/syncobox-shared/dist/syncobox-shared.css";

const apiConfig = {
    identityBaseUrl: process.env.VUE_APP_IDENTITY_BASE_URL,
    redirectBaseUrl: process.env.VUE_APP_REDIRECT_BASE_URL,
    mainBaseUrl: process.env.VUE_APP_MAIN_BASE_URL,
    bimBaseUrl: process.env.VUE_APP_BIM_BASE_URL,
    pcBaseUrl: process.env.VUE_APP_PC_BASE_URL,
    docBaseUrl: process.env.VUE_APP_DOC_BASE_URL
};

//auth
// ref: https://github.com/IdentityModel/oidc-client-js/wiki
const oidc = {
    authority: process.env.VUE_APP_IDENTITY_BASE_URL,
    clientId: "product-configurator-spa",
    redirectUri: process.env.VUE_APP_REDIRECT_BASE_URL + "/callback",
    post_logout_redirect_uri: process.env.VUE_APP_REDIRECT_BASE_URL + "/signout-callback",
    responseType: "code",
    scope: `openid profile offline_access systemadmin.all productconfigurator.all`,
    silentRedirectUri: process.env.VUE_APP_REDIRECT_BASE_URL + "/silent-refresh",
    automaticSilentRenew: true, // If true oidc-client will try to renew your token when it is about to expire
    automaticSilentSignin: false, // If true vuex-oidc will try to silently signin unauthenticated users on public routes. Defaults to true
    silentRequestTimeout: 10000,
    accessTokenExpiringNotificationTime: 60, //Number (in seconds) default is 60
    monitorSession: true,
    checkSessionInterval: 2000,
    revokeAccessTokenOnSignout: false,
    includeIdTokenInSilentRenew: true,
    staleStateAge: 300, // Number (in seconds)
    extraQueryParams: { resource: "some_identifier" },
};

const options = { store, router, apiConfig, oidc };
Vue.use(shared, options);