
var router = {
  path: "/Company/:companyId?",
  name: "productConfig_company",
  component: ()=>import("../../views/Management/Management"),
  children: [
    {
      path: "UploadQuotaManage",
      name: "UploadQuotaManage",
      component: ()=>import("../../views/Management/UploadQuotaManage")
    },
  ],
};

export default router;
