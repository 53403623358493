import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

import Tohatsu from "./Tohatsu";
import Management from "./Management";

// let PORTAL_BASE_URL = process.env.VUE_APP_PORTAL_BASE_URL;
// let isOnline = process.env.NODE_ENV !== "development";

Vue.use(VueRouter);

const routes = [{
        path: "",
        name: "Main",
        component: () =>
            import ("../layouts/Main.vue"),
        children: [
            // {
            //   path: "/",
            //   redirect: () => {
            //     if (isOnline) {
            //       window.location.href = PORTAL_BASE_URL;
            //       return false;
            //     } else {
            //       return { name: "LocalHome" };
            //     }
            //   },
            // },
            {
                path: "/",
                name: "LocalHome",
                component: () =>
                    import ("../views/Home.vue"),
            },
            {
                path: "/profile",
                name: "Profile",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/Profile.vue"),
            },
            Tohatsu,
            Management,
        ],
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/About.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

export default router;