//main.js
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
Vue.config.productionTip = false;

//vuetify
import vuetify from "./plugins/vuetify";

// kendo
import "./plugins/kendo.js";

//shared-framework
import "./plugins/sharedFramework";

// syncobox-production-configurator forge Viewer
import syncoboxPCViewer from "@syncobox/syncobox-product-configurator-viewer";
Vue.use(syncoboxPCViewer);


// syncobox-bim-viewer
import syncoboxBIMViewer from "@syncobox/syncobox-bim-viewer";
Vue.use(syncoboxBIMViewer);

// syncobox-fomio
import syncoboxfomio from "@syncobox/syncobox-formio";
import "@syncobox/syncobox-formio/dist/syncobox-formio.css";
Vue.use(syncoboxfomio, { store });

//vue-notification
import Notifications from "vue-notification";
Vue.use(Notifications);

import syncoboxSharedUi from "@syncobox/syncobox-shared-ui";
import '@syncobox/syncobox-shared-ui/dist/syncobox-shared-ui.css'
Vue.use(syncoboxSharedUi, { store: store });

//i18n
import i18n from "./i18n";

export default new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
