const defaultState = {
  uploadingFileProgress: 0,
};

const mutations = {
  UPLOADING_FILE_PROGRESS(state, percentage) {
    state.uploadingFileProgress = percentage;
  },
};

const getters = {
  uploadingFileProgress: (state) => state.uploadingFileProgress,
};

export default {
  state: defaultState,
  getters,
  mutations,
};
