// import Tohatsu from "../../views/Tohatsu/Tohatsu.vue";
// import ManagePage from "../../views/Tohatsu/ManagePage.vue";
// import Upload from "../../views/Tohatsu/Upload.vue";
// import UploadState from "../../views/Tohatsu/UploadState.vue";
// import UploadQuota from "../../views/Tohatsu/UploadQuota";

var router = {
    path: "/company/:companyId?",
    name: "productConfig_company",
    component: () =>
        import ("../../views/Tohatsu/Tohatsu.vue"),
    children: [{
            path: "managePage",
            name: "ManagePage",
            component: () =>
                import ("../../views/Tohatsu/ManagePage.vue")
        },
        {
            path: "upload",
            name: "Upload",
            component: () =>
                import ("../../views/Tohatsu/Upload.vue")
        },
        {
            path: "uploadState",
            name: "UploadState",
            component: () =>
                import ("../../views/Tohatsu/UploadState.vue")
        },
        {
            path: "uploadQuota",
            name: "UploadQuota",
            component: () =>
                import ("../../views/Tohatsu/UploadQuota.vue"),
        },

    ],
};

export default router;